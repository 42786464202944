<template>
      <!-- <v-dialog> -->
        <v-card class="ma-auto mt-20">
          <v-container style="width=50%" fluid fill-height>
            <v-row justify="center" no-gutters>
              <h3>PayPal Secured Transaction</h3>
            </v-row>
            <v-row class="mt-5 font-weight-bold" justify="center" no-gutters>
              <v-col cols=12 sm=7>
                <v-text-field max-width="2" v-if="!paidFor" v-model="donorName" outlined label="Please Enter Donor Name"></v-text-field>
              </v-col>
            </v-row> 
            <!-- <v-btn @click="debug">Debug</v-btn> -->
            <v-row class="font-weight-bold" justify="center" no-gutters>
              <v-col cols=12 sm=7>
                <v-text-field v-if="!paidFor" v-model="donatedAmount" outlined label="Enter or Select Amount">
                </v-text-field>
              </v-col>
            </v-row>
            <v-row justify="center" no-gutters>
              <v-col cols=12 sm=7>
                <v-radio-group v-model="donatedAmount" row v-if="!paidFor">
                  <v-radio label="$10" color="blue" value="10"></v-radio>
                  <v-radio label="$20" color="blue " value="20"></v-radio>
                  <v-radio label="$30" color="blue " value="30"></v-radio>
                  <v-radio label="$40" color="blue " value="40"></v-radio>
                  <v-radio label="$50" color="blue " value="50"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
            <v-row class="mt-5 font-weight-bold" justify="center" no-gutters>
              <v-col cols=12 sm=7>
                <!-- <v-combobox v-if="!paidFor"
                  background-color="white"
                  v-model="donatedAmount"
                  :items="donateAmount"
                  label="Please Enter or Select an Amount to Donate"
                  outlined
                ></v-combobox> -->
                <v-img v-if="paidFor"
                  height="200px"
                  src="/thankyou300.jpg"
                ></v-img>
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-col cols=12 sm=7>
              <div>
                <div v-if="paidFor">
                  <h1 class="text-center">Thank you very much for supporting my team!</h1>
                </div>
                <div v-if="!paidFor" ref="paypal"></div>
              </div>   
              </v-col>
   
            </v-row>
            <v-row justify="center" class="ma-4">
              <v-btn v-if="!paidFor" @click="goToHome" dark color="blue darken-3" rounded>Cancel</v-btn>
              <v-btn v-if="paidFor" @click="goToHome" dark color="blue darken-3" rounded>Done</v-btn>
            </v-row>
            </v-container>
            <!-- <v-progress-linear v-if="apiRequest" indeterminate class="blue"></v-progress-linear> -->
          </v-card>
</template>

<script>
import 'animate.css'
import { Auth, API, graphqlOperation } from 'aws-amplify';
import { createDonations } from '@/graphql/mutations'
export default {
  name: "HelloWorld",

  data: function() {
    return {
      user: null,
      signedIn: false,
      loaded: false,
      paidFor: false,
      orderNum: null,
      donorName: "",
      // donateAmount: [10,20,30,40,50],
      donatedAmount: "",
      playerName: "",
      teamName: ""
    };
  },
  mounted: function() {
    this.isUserSignedIn()
    const script = document.createElement("script");
    script.src =
      "https://www.paypal.com/sdk/js?client-id=";
    script.addEventListener("load", this.setLoaded);
    document.body.appendChild(script);
  },
  methods: {
    debug(){
      var total = Number(this.donatedAmount) + 5
      console.log(total)
    },
    goToHome(){
      this.$router.push('/player/' + this.playerName)
      // eventBus.$emit('goToSlideShow');
    },
    async isUserSignedIn(){
      try {
        const userObj = await Auth.currentAuthenticatedUser()
          this.signedIn = true
          this.user = userObj
          this.teamName = this.user.attributes.nickname
          this.playerName = this.user.username
          // console.log(this.user.username)
      } catch (err) {
          this.signedIn = false
      }
    },
    setLoaded: function() {
      this.loaded = true;

      window.paypal
        .Buttons({
          createOrder: (data, actions) => {
            return actions.order.create({
              purchase_units: [
                {
                  description: 'Donation for ' + this.teamName + '-' + this.playerName,
                  amount: {
                    currency_code: "USD",
                    value: Number(this.donatedAmount) }}],

              application_context: { shipping_preference: 'NO_SHIPPING' }
            });
      },
      onApprove: async (data, actions) => {
        this.orderNum = await actions.order.capture();
        this.paidFor = true;
        try{

          await API.graphql(graphqlOperation(createDonations,{ input: {
            donor: this.donorName,
            team: this.teamName,
            playerName: this.playerName,
            donatedAmount: this.donatedAmount
          }}))
        } catch(err){
          console.log(err.message)
        }
      },
      onError: err => {
        console.log(err);
          }
        })
        .render(this.$refs.paypal);
    }
  }
};
</script>