<template>
    <div>
        <v-row>
            <v-btn class="ma-5" color="black" dark @click="goBack">Back</v-btn>
        </v-row>
        <h1 class="ma-5 white--text">Before Texting...</h1>
        <ul class="ma-5 white--text">
            <li>Please do not email the link as it is meant to be viewed as webapp in a handheld device...</li>
            <li>Your specific fund-raising link can be found in the dropdown menu...</li>
            <li>Try to use one of the text samples below... the goal is to "Tug Heartstrings First"</li>
                <ul>
                    <li>According to Stanford School of Business (6/27/07), "... researchers found that if organizations want to raise money for a charitable cause, it is far better to appeal to the heart than to the head..."</li>
                </ul>
            <li>Have your child send the text</li>
                <ul>
                    <li>If your donor does not recognize your daugther's number or does not know your daughter, choose and modify one of the samples below that pertains to your situation</li>
                </ul>
            <li>Personalize your text message</li>
                <ul>
                    <li>If the text message comes across as a mass messaging campaign, your donor may not click on the link thinking that your phone got hacked... try to use names and information that only friends and families would know</li>
                </ul>
            <li>Use words that would peak your donors' interest in clicking your fund-raising link... such as...</li>
                <ul>
                    <li>Turn on the brightness and volume before clicking the link...</li>
                </ul>
                <ul>
                    <li>Try to be in a quiet place so you can enjoy the beautiful music...</li>
                </ul>
        </ul>
        <h1 class="ma-5 mt-10 white--text">Text Message Samples</h1>
        <h2 class="ma-5 white--text">1) When donors recognize your child's phone number, use this sample for your child to use and text directly...</h2>
        <p class="text-justify white--text ma-5">{{ sample1 }} <v-btn color=black dark @click="copySample1">Copy Sample 1</v-btn></p>
        <h2 class="ma-5 mt-10 white--text">2) Child does not have a phone number</h2>
        <p class="text-justify white--text ma-5">{{ sample2 }} <v-btn color=black dark @click="copySample2">Copy Sample 2</v-btn></p>
        <h2 class="ma-5 mt-10 white--text">3) Reaching out to donors that don't know your child</h2>
        <p class="text-justify white--text ma-5">{{ sample3 }} <v-btn color=black dark @click="copySample3">Copy Sample 3</v-btn></p>
        <h2 class="ma-5 mt-10 white--text">4) Follow up if there's no response from donors after 4 days</h2>
        <p class="text-justify white--text ma-5">{{ sample4 }} <v-btn color=black dark @click="copySample4">Copy Sample 4</v-btn></p>
        <h2 class="ma-5 mt-10 white--text">5) Still no response after 4 more days </h2>
        <h3 class="ma-5 white--text">Note: some donors may be out on vacation or very busy with a house project</h3>
        <p class="text-justify white--text ma-5">{{ sample5 }} <v-btn color=black dark @click="copySample5">Copy Sample 5</v-btn></p>
    </div>

</template>
<script>
export default {
    data:() => ({
        sample1: "Auntie Jane, I heard what happened to Nina's hand. I hope her hand feels better soon. My <team/scout/club/band> has put something pretty unique and I hope you'll like it... make sure you are in a quiet place before clicking on the link below...",
        sample2: "Uncle Bill, This is Kate... I hope you guys had fun in Cancun. My <team/scout/club/band> put together something special that I think you'll like... turn up the brightness and volume before clicking on the link below...",
        sample3: "Bob, I hope Mary and kids are doing well... I am reaching out to all of my tennis buddies to check out the link below that my daughter's <team/scout/club/band> put together... pls check it out when you're in a quiet place...",
        sample4: "Bill, A couple of days ago my daughter sent you a text from her team. I am just following up to make sure that you know that the link below is legit and not from a hacked phone...",
        sample5: "Bill, My daughter Kate is in a fund-raising competition and can certainly use support from friends and families... when you have a moment, please click the link below..."
    }),
    methods:{
        copySample1(){
        navigator.clipboard.writeText(this.sample1)
        },
        copySample2(){
        navigator.clipboard.writeText(this.sample2)
        },
        copySample3(){
        navigator.clipboard.writeText(this.sample3)
        },
        copySample4(){
        navigator.clipboard.writeText(this.sample4)
        },
        copySample5(){
        navigator.clipboard.writeText(this.sample5)
        },
        goBack(){
            this.$router.push('/')
        }
    }
}
</script>