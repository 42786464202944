/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getItem = /* GraphQL */ `
  query GetItem($id: ID!) {
    getItem(id: $id) {
      id
      name
      playerName
      createdAt
      filterSelected
      Amount
      Status
      updatedAt
      owner
    }
  }
`;
export const listItems = /* GraphQL */ `
  query ListItems(
    $filter: ModelItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        playerName
        createdAt
        filterSelected
        Amount
        Status
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getFilter = /* GraphQL */ `
  query GetFilter($id: ID!) {
    getFilter(id: $id) {
      id
      filterName
      Amount
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listFilters = /* GraphQL */ `
  query ListFilters(
    $filter: ModelFilterFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFilters(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        filterName
        Amount
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getDonations = /* GraphQL */ `
  query GetDonations($id: ID!) {
    getDonations(id: $id) {
      id
      donor
      team
      playerName
      donatedAmount
      Status
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listDonations = /* GraphQL */ `
  query ListDonations(
    $filter: ModelDonationsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDonations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        donor
        team
        playerName
        donatedAmount
        Status
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getViews = /* GraphQL */ `
  query GetViews($id: ID!) {
    getViews(id: $id) {
      id
      owner
      playerName
      viewCount
      createdAt
      updatedAt
    }
  }
`;
export const listViews = /* GraphQL */ `
  query ListViews(
    $filter: ModelViewsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listViews(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        playerName
        viewCount
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
