<template>
    <v-container fill-height>      

    <!-- Login Dialog Box  -->
      <v-dialog v-model="dialogLogin" persistent>
        <v-card :style="{'background-image':'url(/dnd5small.jpg)', 'background-size':'cover', 'margin':'0', 'padding':'0'}">
          <v-container style="width=50%" fluid>
            <!-- <v-row justify="center" align="center" no-gutters>
              <v-col  cols=12 md=4>
                <v-text-field class="ma-3 mt-10 font-weight-bold" v-model="playerName" dark  label="Player Name" outlined rounded></v-text-field>
              </v-col>
            </v-row> -->
            <v-row justify="center" no-gutters>
              <v-col  cols=12 md=4>
                <!-- <v-text-field :rules="[rules.required]" class="mx-3 mt-3" v-model="password" type="password" dark  label="Enter Password" outlined rounded></v-text-field> -->
                <v-text-field  class="mx-3 mt-3 font-weight-bold" v-model="playerPassword" type="password" dark  label="Leave Blank If No Password" outlined rounded></v-text-field>
                <h3 v-if="errorLogin" align=center class="font-weight-bold red--text">{{ errorLogin }}</h3>
              </v-col>  
            </v-row>
            <v-row justify="center" no-gutters>
                <v-btn class="ma-4" color="black" dark @click="signin" rounded>Login<v-icon color="white">mdi-login</v-icon></v-btn>
             </v-row>
              <v-row justify="center" no-gutters>
                  <v-btn class="ml-4 mt-2" color="black" dark @click="dialogForgot=true" rounded>Reset Password<v-icon color="white" >mdi-lock-reset</v-icon></v-btn>              
              </v-row>               
          </v-container>
        </v-card>          
        <v-progress-linear v-if="apiRequest" indeterminate class="blue"></v-progress-linear>

      </v-dialog>
    <!-- Forgot Password  -->
      <v-dialog v-model="dialogForgot">
        <v-card :style="{'background-image':'url(/dnd5small.jpg)', 'background-size':'cover', 'margin':'0', 'padding':'0'}"><v-container style="width=50%" fluid>
          <v-row justify="center">
            <v-col cols=12 md=6>
              <h3 align=center class="white--text">Reset Codes are sent ONLY to player's parents</h3>
            </v-col>
          </v-row><v-row justify="center" no-gutters>
            <v-col  cols=12 md=4>
              <v-text-field class="ma-3 mt-10 font-weight-bold" v-model="playerName" dark  label="Enter Player Name" outlined rounded></v-text-field>
              <h3 v-if="errorForgot" align=center class="font-weight-bold red--text">{{ errorForgot }}</h3>
            </v-col>
          </v-row>
          <v-row justify="center" no-gutters>
              <v-btn class="ma-4" color="black" dark @click="forgot" rounded>Reset Password<v-icon color="white">mdi-ticket-confirmation</v-icon></v-btn>
          </v-row></v-container></v-card>
      </v-dialog>
    <!-- Forgot Password Submit -->
      <v-dialog v-model="dialogForgotSubmit" persistent>
        <v-card :style="{'background-image':'url(/dnd5small.jpg)', 'background-size':'cover', 'margin':'0', 'padding':'0'}"><v-container style="width=50%" fluid>
            <v-row justify="center" no-gutters>
              <v-card-text align="center" class="mt-7 white--text">Please check your email for confirmation code</v-card-text>
              <v-col  cols=12 md=4>
                <v-text-field class="ma-3 font-weight-bold" v-model="newCode" dark  label="Confirmation Code" outlined rounded></v-text-field>
              </v-col>
            </v-row>
            <v-row justify="center" no-gutters>
              <v-col  cols=12 md=4>
                <v-text-field :rules="[rules.required]" class="ma-3 font-weight-bold" v-model="newPassword" type="password" dark  label="Enter New Password" outlined rounded></v-text-field>
                <h3 v-if="errorForgotSubmit" align=center class="font-weight-bold red--text">{{ errorForgotSubmit }}</h3>
              </v-col>
            </v-row>
            <v-row justify="center" no-gutters>
                <v-btn class="ma-4" color="black" dark @click="forgotSubmit" rounded>Submit<v-icon color="white">mdi-file-send-outline</v-icon></v-btn>
            </v-row></v-container>
            <v-progress-linear v-if="apiRequest" indeterminate class="blue"></v-progress-linear>
          </v-card>
      </v-dialog>    
    <!-- Report -->
      <v-dialog v-model="dialogReport">
        <v-card :style="{'background-image':'url(/dnd5small.jpg)', 'background-size':'cover', 'margin':'0', 'padding':'0'}"> 
          <v-row>
            <v-col>
              <!-- <h3 class="white--text">Number of Views: {{ numberOfViews }}</h3> -->
              <h3 class="white--text">2k9 National - Donation Total: ${{ donationTotal }}</h3>
            </v-col>
          </v-row>
          <v-row justify="center" no-gutters>
            <v-card class='mb-10' >
              <!-- <v-card-title>
                  Donor Search
                  <v-spacer></v-spacer>
                  <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="What are you looking for?"
                  single-line
                  hide-details
                  ></v-text-field>
              </v-card-title> -->
              <v-data-table  :headers="headers" :items="results" item-key="id" :sort-by="['donatedAmount']" :sort-desc="[true]" multi-sort class="elevation-1"></v-data-table>
            </v-card>
          </v-row>
        </v-card>
      </v-dialog>
  </v-container>
</template>

<script>


import { Auth, API, graphqlOperation } from 'aws-amplify';
import { listDonations, listViews } from '@/graphql/queries'


export default {
  name: "Slides",
  data() {
    return {
      results: [],
      donationTotal: 0,
      numberOfViews: 0,
      headers: [
        { text: 'Player', value: 'owner' },
        // { text: 'Donor Name', value: 'donor' },
        { text: 'Amount', value: 'donatedAmount' }
      ],
      donors: [],
      dialogReport: true,

      dialogForgotSubmit: false,
      dialogLogin: false,
      dialogForgot: false,

      errorLogin: "",
      errorForgot: "",
      errorForgotSubmit: "",
      errorResendCode: "",

      messageLogin: null,
      newCode: "",
      newPassword: "",
      playerName: "",
      playerPassword: "",
      signedIn: false,
      username: "",
      user: undefined,
      email: "",

      apiRequest: false,
      apiRequestCircular: false,

      rules: {
        required: value => !!value || 'Required.',
        blank: value => !(/[ ]/.test(value)) || 'no spaces allowed',
        counter: value => value.length <= 20 || 'Max 20 characters',
        email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || 'Invalid e-mail.'
        },
      },
    };
  },
  mounted(){
    this.isUserSignedIn()
    this.getDonors()
    this.getViewCount()
  },
  methods:{
    async getViewCount(){
      const listedViews = await API.graphql({ query: listViews })
      const curViewCount = listedViews.data.listViews.items
      this.numberOfViews = curViewCount[0].viewCount
    },
    async getDonors(){
      try {
        const listedDonations = await API.graphql(graphqlOperation(listDonations))
        const fetchedDonations = listedDonations.data.listDonations.items

        let arrayDonations = []
        let fD = fetchedDonations
        fD.forEach(element => {
          arrayDonations.push({
            owner: element.owner,
            donor: element.donor,
            donatedAmount: element.donatedAmount
          })
        this.donationTotal = this.donationTotal + element.donatedAmount
        })

        this.donors = arrayDonations.sort((a,b) => Number(a.donatedAmount) - Number(b.donatedAmount))

        var result = []
        this.donors.reduce(function(res, value) {
          if (!res[value.owner]) {
            res[value.owner] = { owner: value.owner, donatedAmount: 0 };
            result.push(res[value.owner])
          }
          res[value.owner].donatedAmount += value.donatedAmount;
          return res;
        }, {});

        this.results=result

      } catch(err) {
          console.log(err.message)
      }
    },
    async isUserSignedIn(){
      try {
        const userObj = await Auth.currentAuthenticatedUser()
          this.signedIn = true
          this.user = userObj
      } catch (err) {
          this.signedIn = false
          this.dialogLogin = true
          this.apiRequest = false
      }
    },
    async signin(){
      try {
        await Auth.signIn({username: 'admin', password: this.playerPassword })
        this.listItemsC(this.playIf)        
        this.dialogReport = true
      } catch(err) {
        this.errorLogin = err.message
      }
    },
    async forgot(){
      try {
        await Auth.forgotPassword(this.playerName)
        this.dialogForgot = false
        this.dialogLogin = false
        this.dialogForgotSubmit = true
      } catch(err){
        this.errorForgot = err.message
      }
    },
    async forgotSubmit(){
      this.apiRequest=true
      this.errorLogin = null
      this.messageLogin = null
      try {
        await Auth.forgotPasswordSubmit(this.playerName, this.newCode, this.newPassword)
        this.playerPassword = this.newPassword
        this.dialogForgotSubmit=false
        this.messageLogin = 'Succesful password reset... login again.'
        this.dialogLogin=true
        this.apiRequest=false
      } catch(err){
        this.errorForgotSubmit = err.message
      }
      this.apiRequest=false
    },
    async signOut() {
      try {
          await Auth.signOut();
          this.musicOff()
          this.drawer=false
          this.user=null
          this.signedIn=false
          this.dialogPhotos = false
          this.dialogLogin = true
          this.items = []
      } catch (err) {
          alert(err.message)
      }
    },
  },
};
</script>

<style>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  margin-top: 6px;
  margin: 0;
  padding: 0;
}

#vol{
  text-align: center;
  margin-top: 20px;
}

h3{
  text-align: center;
}

</style>
