import Vue from 'vue'
import VueRouter from 'vue-router'
import Slides from '../components/Slides.vue'
import PayPal from '../components/PayPal.vue'
import Player from '../components/Player.vue'
import Total from '../components/Total.vue'
import Intro from '../components/Intro.vue'
import Sample from '../components/Sample.vue'
import { Auth } from 'aws-amplify';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Slides',
    component: Slides
  },
  {
    path: '/player/:playerName',
    name: 'Player',
    component: Player
  },
  {
    path: '/intro',
    name: 'Intro',
    component: Intro
  },
  {
    path: '/sample',
    name: 'Sample',
    component: Sample
  },
  {
    path: '/paypal',
    name: 'PayPal',
    component: PayPal,
    meta: { requiresAuth: true }
  },
  {
    path: '/total',
    name: 'Total',
    component: Total,
    meta: { requiresAuth: true }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const isAuthenticated = await Auth.currentUserInfo();

  if (requiresAuth && !isAuthenticated) {
    next("/");
  } else {
    next()
  }

})

export default router
