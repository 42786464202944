/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createItem = /* GraphQL */ `
  mutation CreateItem(
    $input: CreateItemInput!
    $condition: ModelItemConditionInput
  ) {
    createItem(input: $input, condition: $condition) {
      id
      name
      playerName
      createdAt
      filterSelected
      Amount
      Status
      updatedAt
      owner
    }
  }
`;
export const updateItem = /* GraphQL */ `
  mutation UpdateItem(
    $input: UpdateItemInput!
    $condition: ModelItemConditionInput
  ) {
    updateItem(input: $input, condition: $condition) {
      id
      name
      playerName
      createdAt
      filterSelected
      Amount
      Status
      updatedAt
      owner
    }
  }
`;
export const deleteItem = /* GraphQL */ `
  mutation DeleteItem(
    $input: DeleteItemInput!
    $condition: ModelItemConditionInput
  ) {
    deleteItem(input: $input, condition: $condition) {
      id
      name
      playerName
      createdAt
      filterSelected
      Amount
      Status
      updatedAt
      owner
    }
  }
`;
export const createFilter = /* GraphQL */ `
  mutation CreateFilter(
    $input: CreateFilterInput!
    $condition: ModelFilterConditionInput
  ) {
    createFilter(input: $input, condition: $condition) {
      id
      filterName
      Amount
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateFilter = /* GraphQL */ `
  mutation UpdateFilter(
    $input: UpdateFilterInput!
    $condition: ModelFilterConditionInput
  ) {
    updateFilter(input: $input, condition: $condition) {
      id
      filterName
      Amount
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteFilter = /* GraphQL */ `
  mutation DeleteFilter(
    $input: DeleteFilterInput!
    $condition: ModelFilterConditionInput
  ) {
    deleteFilter(input: $input, condition: $condition) {
      id
      filterName
      Amount
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createDonations = /* GraphQL */ `
  mutation CreateDonations(
    $input: CreateDonationsInput!
    $condition: ModelDonationsConditionInput
  ) {
    createDonations(input: $input, condition: $condition) {
      id
      donor
      team
      playerName
      donatedAmount
      Status
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateDonations = /* GraphQL */ `
  mutation UpdateDonations(
    $input: UpdateDonationsInput!
    $condition: ModelDonationsConditionInput
  ) {
    updateDonations(input: $input, condition: $condition) {
      id
      donor
      team
      playerName
      donatedAmount
      Status
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteDonations = /* GraphQL */ `
  mutation DeleteDonations(
    $input: DeleteDonationsInput!
    $condition: ModelDonationsConditionInput
  ) {
    deleteDonations(input: $input, condition: $condition) {
      id
      donor
      team
      playerName
      donatedAmount
      Status
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createViews = /* GraphQL */ `
  mutation CreateViews(
    $input: CreateViewsInput!
    $condition: ModelViewsConditionInput
  ) {
    createViews(input: $input, condition: $condition) {
      id
      owner
      playerName
      viewCount
      createdAt
      updatedAt
    }
  }
`;
export const updateViews = /* GraphQL */ `
  mutation UpdateViews(
    $input: UpdateViewsInput!
    $condition: ModelViewsConditionInput
  ) {
    updateViews(input: $input, condition: $condition) {
      id
      owner
      playerName
      viewCount
      createdAt
      updatedAt
    }
  }
`;
export const deleteViews = /* GraphQL */ `
  mutation DeleteViews(
    $input: DeleteViewsInput!
    $condition: ModelViewsConditionInput
  ) {
    deleteViews(input: $input, condition: $condition) {
      id
      owner
      playerName
      viewCount
      createdAt
      updatedAt
    }
  }
`;
