/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:7f02303b-567d-493c-bf3c-e57d0d7e4d57",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_vheQ2lgpk",
    "aws_user_pools_web_client_id": "1frjl957edl6soio3ls3tumv37",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://hhvzlzdb7bhijfi553jf2dpbvu.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_user_files_s3_bucket": "fundraising20211125b194116-main",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
