<template>
    <v-container fill-height>      
      <v-app-bar app clipped-left color=black>
        <v-avatar class="mr-2" @click.stop="drawer = !drawer" size="36px"><img src="/Logo.jpg" alt="Avatar"></v-avatar>
      </v-app-bar>
    <!-- Show Image   -->
      <v-row  class="justify-center align-center">
        <v-col>
          <carousel-3d v-if="showSlideShow" :autoplay="true" :autoplayTimeout="2000" :perspective="70" :controls-visible="true" :controls-prev-html="'&#10092;'" :controls-next-html="'&#10093;'" 
                :controls-width="30" :controls-height="60" :clickable="false" :count="items.length" :border="0" :height="240">
            <slide v-for="(item, i) in items" :index="i" :key="i">
              <figure>
                <img v-bind:src="item.url">
              </figure>
            </slide>
          </carousel-3d>
        </v-col>
      </v-row>
    <!-- Navigation Drawer -->
      <v-navigation-drawer v-model="drawer" absolute temporary>
        <v-list shaped>
          <h3 class="mt-8" v-if="user">Player Name -  {{ user.username }}</h3>
            <v-list-item-group v-model="item" color="blue darken-3">
            <v-list-item v-if="!user" @click="goToLogin"><v-list-item-icon><v-icon color=black>mdi-login</v-icon></v-list-item-icon><v-list-item-title>Login</v-list-item-title></v-list-item>
            <v-list-item @click="goToIntro"><v-list-item-icon><v-icon color=black>mdi-newspaper-variant-outline</v-icon></v-list-item-icon><v-list-item-title>Intro</v-list-item-title></v-list-item>
            <v-list-item v-if="user" @click="goToPhotos"><v-list-item-icon><v-icon color=black>mdi-camera</v-icon></v-list-item-icon><v-list-item-title>Photos</v-list-item-title></v-list-item>
            <v-list-item @click="goToSample"><v-list-item-icon><v-icon color=black>mdi-arrange-send-to-back</v-icon></v-list-item-icon><v-list-item-title>Samples</v-list-item-title></v-list-item>
            <v-list-item v-if="user" @click="goToLink"><v-list-item-icon><v-icon color=black>mdi-link</v-icon></v-list-item-icon><v-list-item-title>Donor's Link</v-list-item-title></v-list-item>
            <v-list-item v-if="user" @click="goToReport"><v-list-item-icon><v-icon color=black>mdi-chart-timeline</v-icon></v-list-item-icon><v-list-item-title>Report</v-list-item-title></v-list-item>
            <v-list-item v-if="!showSlideShow" @click="goToSlideShow"><v-list-item-icon><v-icon color=black>mdi-restart</v-icon></v-list-item-icon><v-list-item-title>Restart Show</v-list-item-title></v-list-item>
            <v-list-item v-if="!playMusic" @click="musicOn"><v-list-item-icon><v-icon color=black>mdi-music</v-icon></v-list-item-icon><v-list-item-title>Play Music</v-list-item-title></v-list-item>
            <v-list-item v-if="playMusic" @click="musicOff"><v-list-item-icon><v-icon color=black>mdi-music-off</v-icon></v-list-item-icon><v-list-item-title>Stop Music</v-list-item-title></v-list-item>
            <!-- <v-list-item @click="goToPayPal"><v-list-item-icon><v-icon color=black>mdi-gift</v-icon></v-list-item-icon><v-list-item-title>Donate</v-list-item-title></v-list-item> -->
            <v-list-item @click="goToMusicCredit"><v-list-item-icon><v-icon color=black>mdi-comment-quote</v-icon></v-list-item-icon><v-list-item-title>Music Credit</v-list-item-title></v-list-item>
            <v-list-item @click="goToBug"><v-list-item-icon><v-icon color=black>mdi-bug</v-icon></v-list-item-icon><v-list-item-title>Report a Bug</v-list-item-title></v-list-item>
            <v-list-item @click="goToCustomize"><v-list-item-icon><v-icon color=black>mdi-wrench-outline</v-icon></v-list-item-icon><v-list-item-title>Customize</v-list-item-title></v-list-item>
            <v-list-item @click="goToIdea"><v-list-item-icon><v-icon color=black>mdi-head-lightbulb-outline</v-icon></v-list-item-icon><v-list-item-title>Got an app idea?</v-list-item-title></v-list-item>
            <v-list-item v-if="user" @click="signOut"><v-list-item-icon><v-icon color=black>mdi-logout</v-icon></v-list-item-icon><v-list-item-title>Logout</v-list-item-title></v-list-item>
            </v-list-item-group>
        </v-list>
      </v-navigation-drawer>
    <!-- Login Dialog Box  -->
      <v-dialog v-model="dialogLogin">
        <v-card :style="{'background-image':'url(/window.jpg)', 'background-size':'cover', 'margin':'0', 'padding':'0'}">
          <v-container style="width=50%" fluid>
            <v-row justify="center" align="center" no-gutters>
              <v-col cols=12>
                <v-text-field class="ma-3 mt-5 font-weight-bold" v-model="playerName" dark  label="Player Name (no spaces)" outlined rounded></v-text-field>              
              </v-col>
            </v-row>
            <v-row justify="center" no-gutters>
              <v-col cols=12>
                <v-text-field  class="mx-3 font-weight-bold" v-model="playerPassword" type="password" dark  label="Player Password" outlined rounded></v-text-field>
                <h3 v-if="errorLogin" align=center class="font-weight-bold red--text">{{ errorLogin }}</h3>
              </v-col>  
            </v-row>

            <v-row justify="center" no-gutters>
                <v-btn class="ma-1" color="black" dark @click="signin" rounded>Login<v-icon color="white">mdi-login</v-icon></v-btn>
             </v-row>
            <v-row justify="center" no-gutters>
                <v-btn class="ma-1" color="black" dark @click="dialogRegister=true" rounded>Register<v-icon color="white" >mdi-account-plus</v-icon></v-btn>
            </v-row>
            <v-row justify="center" no-gutters>
                <v-btn class="ma-1" color="black" dark @click="dialogForgot=true" rounded>Reset Password<v-icon color="white" >mdi-lock-reset</v-icon></v-btn>              
            </v-row>
            <v-row justify="center" no-gutters>
                <h4 class="white--text mt-2 mx-7">Warning: Upload photos meant ONLY for Internet consumption</h4>              
            </v-row>               
          </v-container>
        </v-card>          
        <v-progress-linear v-if="apiRequest" indeterminate class="blue"></v-progress-linear>

      </v-dialog>
    <!-- Register Dialog Box  -->
      <v-dialog v-model="dialogRegister">
        <v-card :style="{'background-image':'url(/window.jpg)', 'background-size':'cover', 'margin':'0', 'padding':'0'}">
          <v-container style="width=50%" fluid>
            <!-- <v-row justify="center" no-gutters>
              <v-col  cols=12 md=6>
                <v-select v-model="teamName" :items="teams" dark  label="Team Name" outlined rounded></v-select>
              </v-col>
            </v-row>   -->
            <v-row justify="center" no-gutters> 
              <v-col  cols=12 md=6>
                  <v-text-field :rules="[rules.required, rules.email]" class="ma-3 mt-10" v-model="editorEmail" type="email" dark  label="Enter Email Address" outlined rounded></v-text-field>
              </v-col>
            </v-row>
            <v-row justify="center" no-gutters>
              <v-col  cols=12 md=6>
                  <v-text-field :rules="[rules.required, rules.blank]" class="ma-3" v-model="playerName" dark  label="Player (such as kate or kateh)" outlined rounded></v-text-field>
              </v-col>
            </v-row>
            <v-row justify="center" no-gutters>
              <v-col  cols=12 md=6>
                  <v-text-field :rules="[rules.required]" class="mx-3 mt-3" v-model="playerPassword" type="password" dark  label="Player Password" outlined rounded></v-text-field>
              </v-col>
            </v-row>
            <v-row justify="center" no-gutters>
              <v-col  cols=12 md=6>
                <v-checkbox dark v-model="checkboxAgreement" label="I acknowledge that my photos are meant for my donors but can potentially be viewed by the public."></v-checkbox>
              </v-col>
            </v-row>

            <v-row justify="center" no-gutters>
              <v-col  cols=12 md=6>
                <h3 v-if="errorRegister" align=center class="red--text">{{ errorRegister }}</h3>
              </v-col>
            </v-row>            
            <v-row justify="center" no-gutters>
                <v-btn class="ma-4" color="black" dark @click="register" rounded>Register<v-icon color="white">mdi-account-plus</v-icon></v-btn>
                <v-btn class="ma-4" color="black" dark @click="backToLogin" rounded>Back<v-icon color="white">mdi-arrow-left-circle</v-icon></v-btn>
            </v-row>
          </v-container>
          <v-progress-linear v-if="apiRequest" indeterminate class="blue"></v-progress-linear>
        </v-card>
      </v-dialog>
    <!-- Signup Confirmation  -->
      <v-dialog v-model="dialogConfirm" persistent>
        <v-card :style="{'background-image':'url(/window.jpg)', 'background-size':'cover', 'margin':'0', 'padding':'0'}"><v-container style="width=50%" fluid>
            <v-row justify="center" no-gutters>
              <v-card-text align="center" class="mt-7 white--text">Please check your email for confirmation code</v-card-text>
              <v-col cols=12 md=4>
                <v-text-field class="ma-3 mt-1 font-weight-bold" v-model="confirmCode" dark  label="Confirmation Code" outlined rounded></v-text-field>
                <h3 v-if="errorConfirm" align=center class="font-weight-bold red--text">{{ errorConfirm }}</h3>
                <h3 v-if="errorResendCode" align=center class="font-weight-bold red--text">{{ errorResendCode }}</h3>
              </v-col>
            </v-row>
            <v-row justify="center" no-gutters>
                <v-btn class="ma-4" color="black" dark @click="confirmSignUp" rounded>Confirm<v-icon color="white">mdi-ticket-confirmation</v-icon></v-btn>
                <v-btn class="ma-4" color="black" dark @click="resendCode" rounded>Re-send Code<v-icon color="white">mdi-email-sync-outline</v-icon></v-btn>
            </v-row></v-container>
            <v-progress-linear v-if="apiRequest" indeterminate class="blue"></v-progress-linear>
            </v-card>
      </v-dialog>
    <!-- Forgot Password  -->
      <v-dialog v-model="dialogForgot">
        <v-card :style="{'background-image':'url(/window.jpg)', 'background-size':'cover', 'margin':'0', 'padding':'0'}"><v-container style="width=50%" fluid>
          <v-row justify="center">
            <v-col cols=12 md=6>
              <h3 align=center class="white--text">Reset Codes are sent ONLY to player's parents</h3>
            </v-col>
          </v-row><v-row justify="center" no-gutters>
            <v-col  cols=12 md=4>
              <v-text-field class="ma-3 mt-10 font-weight-bold" v-model="playerName" dark  label="Enter Player Name" outlined rounded></v-text-field>
              <h3 v-if="errorForgot" align=center class="font-weight-bold red--text">{{ errorForgot }}</h3>
            </v-col>
          </v-row>
          <v-row justify="center" no-gutters>
              <v-btn class="ma-4" color="black" dark @click="forgot" rounded>Reset Password<v-icon color="white">mdi-ticket-confirmation</v-icon></v-btn>
          </v-row></v-container></v-card>
      </v-dialog>
    <!-- Forgot Password Submit -->
      <v-dialog v-model="dialogForgotSubmit" persistent>
        <v-card :style="{'background-image':'url(/window.jpg)', 'background-size':'cover', 'margin':'0', 'padding':'0'}"><v-container style="width=50%" fluid>
            <v-row justify="center" no-gutters>
              <v-card-text align="center" class="mt-7 white--text">Please check your email for confirmation code</v-card-text>
              <v-col  cols=12 md=4>
                <v-text-field class="ma-3 font-weight-bold" v-model="newCode" dark  label="Confirmation Code" outlined rounded></v-text-field>
              </v-col>
            </v-row>
            <v-row justify="center" no-gutters>
              <v-col  cols=12 md=4>
                <v-text-field :rules="[rules.required]" class="ma-3 font-weight-bold" v-model="newPassword" type="password" dark  label="Enter New Password" outlined rounded></v-text-field>
                <h3 v-if="errorForgotSubmit" align=center class="font-weight-bold red--text">{{ errorForgotSubmit }}</h3>
              </v-col>
            </v-row>
            <v-row justify="center" no-gutters>
                <v-btn class="ma-4" color="black" dark @click="forgotSubmit" rounded>Submit<v-icon color="white">mdi-file-send-outline</v-icon></v-btn>
            </v-row></v-container>
            <v-progress-linear v-if="apiRequest" indeterminate class="blue"></v-progress-linear>
          </v-card>
      </v-dialog>    
   <!-- No Photo To Show Page -->
      <v-dialog v-model="showNoPhotoPage">
        <v-card :style="{'background-image':'url(/window.jpg)', 'background-size':'cover', 'margin':'0', 'padding':'0'}">
          <v-container style="width=50%" fluid>
            <v-row justify="center" no-gutters>
              <h2 class="my-15 white--text">No Photos have been uploaded yet... pls click on your team logo in top left corner to upload photos...</h2>
            </v-row>
            <v-row justify="center" class="ma-4">
              <v-btn @click="showNoPhotoPage=false">OK</v-btn>
            </v-row>
            </v-container>
            <v-progress-linear v-if="apiRequest" indeterminate class="blue"></v-progress-linear>
          </v-card>
      </v-dialog>
   <!-- Filter Information -->
      <v-dialog v-model="showFilterInfo">
        <v-card :style="{'background-image':'url(/window.jpg)', 'background-size':'cover', 'margin':'0', 'padding':'0'}">
          <v-container style="width=50%" fluid>
            <v-row justify="center" no-gutters>
              <h3 class="my-15 white--text">Applying a Filter is not required but if you would like to enhance a photo, it is currently a manual process and will incur cost that is not part of fund-raising... </h3>
            </v-row>
            <v-row class="ma-4" justify="center">
              <v-btn @click="ackFilterInfo">OK</v-btn>
            </v-row>
            </v-container>
            <v-progress-linear v-if="apiRequest" indeterminate class="blue"></v-progress-linear>
          </v-card>
      </v-dialog>
   <!-- Music Creidt -->
      <v-dialog v-model="dialogMusicCredit">
        <v-card :style="{'background-image':'url(/window.jpg)', 'background-size':'cover', 'margin':'0', 'padding':'0'}">
          <v-img
            height="200px"
            src="https://dndhiglights-myapp2-net.s3.amazonaws.com/piano-ga18e9ea77_1920.jpg"
          ></v-img>
            <v-row justify="center" no-gutters>
              <h2 class="my-15 mx-5 white--text">Background Music is credited to Fesliyan Studios </h2>
            </v-row>
          </v-card>
      </v-dialog>
    <!-- Report Bug -->
      <v-dialog v-model="dialogReportBug">
        <v-card :style="{'background-image':'url(/window.jpg)', 'background-size':'cover', 'margin':'0', 'padding':'0'}">
          <v-img
            height="200px"
            src="https://cdn.pixabay.com/photo/2020/07/12/07/47/bee-5396362_1280.jpg"></v-img>
            <v-row justify="center" no-gutters>
              <h2 class="my-15 mx-5 white--text">Please email buildmyapp2@yahoo.com if you encountered a bug... </h2>
            </v-row>
          </v-card>
      </v-dialog>
    <!-- Customize -->
      <v-dialog v-model="dialogCustomize">
        <v-card :style="{'background-image':'url(/window.jpg)', 'background-size':'cover', 'margin':'0', 'padding':'0'}">
          <v-img
            height="200px"
            src="https://dndhiglights-myapp2-net.s3.amazonaws.com/customize.jpg"></v-img>
            <v-row justify="center" no-gutters>
              <h2 class="my-15 mx-5 white--text">Please email buildmyapp2@yahoo.com if you would like for us to build a different type of photo slide app... </h2>
            </v-row>
          </v-card>
      </v-dialog>
    <!-- Got Idea -->
      <v-dialog v-model="dialogGotIdea">
        <v-card :style="{'background-image':'url(/window.jpg)', 'background-size':'cover', 'margin':'0', 'padding':'0'}">
          <v-img
            height="200px"
            src="https://dndhiglights-myapp2-net.s3.amazonaws.com/gotideas.jpg"></v-img>
            <v-row justify="center" no-gutters>
              <h2 class="my-15 mx-5 white--text">Please email buildmyapp2@yahoo.com if you have an app idea that you would like to partner with us...</h2>
            </v-row>
          </v-card>
      </v-dialog>
    <!-- Missing Info Alert -->
        <v-dialog v-model="alertMissingInfo" persistent max-width="290">
          <v-card>
            <v-card-title class="red headline" style="font-weight:bold; color:white;">Alert</v-card-title>
            <v-card-text>Please enter the missing info!</v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="alertMissingInfo = false">Close</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
    <!-- Edit Photos List -->
      <v-dialog v-model="dialogPhotos" fill-height>
        <v-card :style="{'background-image':'url(/window.jpg)', 'background-size':'cover', 'margin':'0', 'padding':'0'}">
          <v-container style="width=50%" fluid>
            <v-row style="position: relative;">
              <v-btn @click="closePhotos" fab absolute top right class="mt-11"><v-icon>mdi-close-circle</v-icon></v-btn>
              <v-col cols=12>
                <v-card v-if="!reached30">
                  <v-card-title>Upload</v-card-title>
                  <v-file-input
                    accept="image/*"
                    label = "Click here to add photo"
                    prepend-icon = "mdi-plus"
                    @change="imageProcess"
                    v-model="uploadFile"
                    outlined
                    class="mx-10"
                  ></v-file-input>
                  <h4 class="mx-5">Note: Try to find action shots or happy/cute/adorable Kodak moments </h4>
                </v-card>
                <v-card v-if="reached30">
                  <h3 class="mt-15">Max of 30 photos... Delete Photos if need to change photos... </h3>
                </v-card>
                <v-overlay v-if="apiRequest"><v-progress-circular indeterminate size="90" color="primary"></v-progress-circular></v-overlay>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" v-for="(item, index) in items" :key="index">
                <v-dialog width="unset">
                  <template v-slot:activator="{ on, attrs }">
                    <v-card class="mb-2">
                      <v-card-text></v-card-text>
                      <v-img
                        contain
                        aspect-ratio="1.7"
                        :src="item.url"
                        max-height="400"
                        v-bind="attrs"
                        v-on="on"
                      ></v-img>
                      <v-row justify=center>
                        <v-btn v-if="item.amount" class="text-truncate" absolute top center color=black dark @click="goToFilter(item)">${{ item.amount }} Filter<v-icon color=green>mdi-image-album</v-icon></v-btn>
                        <v-btn v-if="!item.amount" class="text-truncate" absolute top center color=black dark @click="goToFilter(item)">Filter<v-icon color=white>mdi-image-album</v-icon></v-btn>
                      </v-row>
                      <v-row justify=center>
                        <v-btn class="mb-2" color=black @click="removePhoto(item)"><v-icon color=white>mdi-trash-can-outline</v-icon></v-btn>
                      </v-row>
                      <!-- <v-card-text class="text-truncate">{{ item.name }}</v-card-text> -->
                    </v-card>
                    <v-overlay v-if="apiRequest"><v-progress-circular indeterminate size="90" color="primary"></v-progress-circular></v-overlay>
                  </template>
                </v-dialog>
              </v-col>
            </v-row>
            <v-row v-if="filterTotal > 0" justify="center" class="white--text mx-auto">
              <h3 class="white--text">Total: ${{ filterTotal }}</h3>
            </v-row>
            <v-row v-if="filterTotal > 0" justify="center">
              <v-card class="mx-4 my-4">
                <v-card-text>Please Zelle or Venmo the total amount to buildmyapp2@yahoo.com or 904-392-7442... Once payment is received, we'll start adding filter(s) to the corresponding photo(s)... Thank you.</v-card-text>
              </v-card>
            </v-row>

          </v-container>
            
            </v-card>
      </v-dialog>
    <!-- Filter -->
      <v-dialog v-model="dialogFilter">
        <v-card :style="{'background-image':'url(/window.jpg)', 'background-size':'cover', 'margin':'0', 'padding':'0'}"><v-container style="width=50%" fluid>
          <v-row class="white--text" justify="center" no-gutters>
            <v-btn dark color="blue darken-3" href="https://myathlete.myapp2.net/filters">Click Here for Filter Samples</v-btn>
          </v-row>
          <v-row justify="center">
            <v-img
              contain
              aspect-ratio="1.7"
              :src="this.item.url"
              max-height="400"
            ></v-img>
          </v-row>
          <v-row>
            <v-select @input="orderFilter(selectedFilter)" class="my-5 mx-4" max-width="20px" label="Please select a filter" dense outlined dark v-model="selectedFilter" :items="filters" item-value="selectedFilter"  item-text="combo" return-object>
            </v-select>
          </v-row>
          <v-row class="ma-4" justify="center">
            <v-btn @click="dialogFilter=false">Close</v-btn>
          </v-row>          
            </v-container>
            <v-progress-linear v-if="apiRequest" indeterminate class="blue"></v-progress-linear>
          </v-card>
      </v-dialog>    
    <!-- Report -->
      <v-dialog v-model="dialogReport">
        <v-card class="pa-5" :style="{'background-image':'url(/window.jpg)', 'background-size':'cover', 'margin':'0', 'padding':'0'}"> 
          <v-row>
            <v-col>
              <h3 class="white--text">Number of Views: {{ numberOfViews }}</h3>
              <h3 class="white--text">Donation Total: ${{ donationTotal }}</h3>
            </v-col>
          </v-row>
          <v-row class="mt-3" justify="center" no-gutters>
            <v-card>
              <v-data-table :headers="headers" :items="donors" item-key="id" :search="search" :sort-by="['donor']" :sort-desc="[false, true]" multi-sort class="elevation-1"></v-data-table>
            </v-card>
          </v-row>
          <v-row justify="center" class="ma-4">
            <v-btn class="mx-2" color="black" dark @click="dialogReport=false">Close</v-btn>
            <v-btn class="mx-2" color="black" dark @click="goToReport">Refresh</v-btn>
          </v-row>
        </v-card>
      </v-dialog>
    <!-- Donor's Link -->
      <v-dialog v-model="dialogLink">
        <v-card :style="{'background-image':'url(/window.jpg)', 'background-size':'cover', 'margin':'0', 'padding':'0'}">
            <v-row justify="center" no-gutters>
              <h3 class="mt-5 mx-5 white--text">Once all photos are uploaded, text the link below to your donors... please use one of the text message samples in order to maximize your donations... samples can be found in the dropdown menu...</h3>
            </v-row>
            <v-row justify="center" no-gutters>
              <h4 @click="goLink" class="ma-10 white--text">{{ donorsLink }}</h4>
            </v-row>
            <v-row justify="center" no-gutters>
              <v-btn class="mx-4" color="black" dark @click="copyLink">Copy Link</v-btn>
            </v-row>
          </v-card>
      </v-dialog>
  </v-container>
</template>

<script>
import {eventBus} from "/src/main.js"; 
import { Carousel3d, Slide } from "vue-carousel-3d";
import { useSound } from '@vueuse/sound'
import buttonSfx from '../assets/audio.mp3'
import { Auth, API, graphqlOperation, Storage } from 'aws-amplify';
import { listItems, listDonations, listViews } from '@/graphql/queries'
import { createItem, deleteItem, updateItem, createViews  } from '@/graphql/mutations'

export default {
  name: "Slides",
  components: {
    Carousel3d,
    Slide
  },
  data() {
    return {
      reached30: false,
      dialogLink: false,
      donorsLink: "",
      userReturn: null,
      checkboxAgreement: false,
      donationTotal: 0,
      numberOfViews: 0,
      headers: [
        { text: 'Donor Name', value: 'donor' },
        { text: 'Amount', value: 'donatedAmount' }
      ],
      donors: [],
      search: "",
      dialogReport: false,
      showSlideShow: true,
      loaded: false,
      showPayPal: false,
      teamName: "",
      teams: ["DnD Dynasty 09 Stephanie", "DnD Dynasty 11u Baseball","DnD Dynasty 12U Don","DnD Dynasty 2829 Distiny","DnD Dynasty 2k10 Hunter","DnD Dynasty 2k11 Chad","DnD Dynasty 2k12 Misty","DnD Dynasty 2k7 Tracy","DnD Dynasty 2k8 Billy","DnD Dynasty 2k9 National","Excel Elite 16U Urias"],
      filterTotal: 0,
      showFilterInfo: false,
      showFilterInfoFirst: true,
      image: "",
      resizedPhoto: undefined,
      photos: [],
      selectedFilter: [],
      filters: [
        { filterName: 'No Filter', amount: 0, combo: 'No Filter - $0'},
        { filterName: 'Name Title', amount: 1, combo: 'Name Title - $1'},
        { filterName: 'Pencil Sketch', amount: 2, combo: 'Pencil Sketch - $2'},
        { filterName: 'Wall Paint', amount: 3, combo: 'Wall Paint - $3'},
        { filterName: 'Blur', amount: 5, combo: 'Blur - $5'},
        { filterName: 'Outline Blur', amount: 5, combo: 'Outline Blur - $5'},
        { filterName: 'Paint Splash', amount: 5, combo: 'Paint Splash - $5'},
        { filterName: 'Spot Color', amount: 5, combo: 'Spot Color - $5'},
      ],
      filter: [],
      dialogFilter: false,
      dialogGotIdea: false,
      dialogCustomize: false,
      dialogReportBug: false,
      dialogMusicCredit: false,
      errorEditorPassword: "",
      dialogEditorPassword: false,
      chkEditorPassword: "",
      isEditor: false,
      showNoPhotoPage: false,
      switch1: false,
      switch2: true,
      setNextToken: null,
      playMusic: false,
      uploadFile: null,
      file: null,
      items: [],
      item: [],

      alertMissingInfo: "",
      confirmCode: null,
      dialogConfirm: false,
      dialogForgotSubmit: false,
      dialogLogin: false,
      dialogRegister: false,
      dialogForgot: false,
      dialogPhotos: false,

      errorLogin: "",
      errorRegister: "",
      errorConfirm: "",
      errorForgot: "",
      errorForgotSubmit: "",
      errorResendCode: "",

      messageLogin: null,
      newCode: "",
      newPassword: "",
      playerName: "",
      playerPassword: "",
      editorEmail: "",
      editorPassword: "",
      signedIn: false,
      username: "",
      user: undefined,
      email: "",
 
      rules: {
        required: value => !!value || 'Required.',
        blank: value => !(/[ ]/.test(value)) || 'no spaces allowed',
        counter: value => value.length <= 20 || 'Max 20 characters',
        email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || 'Invalid e-mail.'
        },
      },

      apiRequest: false,
      apiRequestCircular: false,
      drawer: false,
      startSlide: false,

    };
  },
  setup() {
    const { play, stop } = useSound(buttonSfx, {onend: () => { play() },})
    return {
      play,
      stop,
    }
  },
  computed:{
    playSound(){
      return this.play
    },
    size () {
      const size = {xs:'x-small',sm:'small',lg:'large',xl:'x-large'}[this.$vuetify.breakpoint.name];
      return size ? { [size]: true } : {}
    }
  },
  created() {
    eventBus.$on('goToSlideShow', () => {
            this.goToSlideShow();
    })
  },
  mounted(){
    this.isUserSignedIn()
    this.getDonors()
    this.getViewCount()
  },
  methods:{
    async getViewCount(){
      const listedViews = await API.graphql({ query: listViews })
      const curViewCount = listedViews.data.listViews.items
      this.numberOfViews = curViewCount[0].viewCount
    },
    async getDonors(){
      try {
        const listedDonations = await API.graphql(graphqlOperation(listDonations))
        const fetchedDonations = listedDonations.data.listDonations.items

        let arrayDonations = []
        let fD = fetchedDonations
        fD.forEach(element => {
          arrayDonations.push({
            donor: element.donor,
            donatedAmount: element.donatedAmount
          })
        this.donationTotal = this.donationTotal + element.donatedAmount
        })

        this.donors = arrayDonations.sort((a,b) => Number(a.donatedAmount) - Number(b.donatedAmount))

      } catch(err) {
          console.log(err.message)
      }
    },
    musicOn(){
      this.playSound()
      this.playMusic = true
    },
    musicOff(){
      this.playMusic = false
      this.stop()
    },
    imageProcess(){
      if(!this.uploadFile){
        return;
      }
      this.resize().then((resultResize)=> {
        console.log(resultResize)
        return this.upload();
      })
    },
    async resize(){
      return new Promise((resolve) => {
        this.apiRequest = true
   
      const reader = new FileReader();
      reader.readAsDataURL(this.uploadFile);

      reader.onload = (event) => {
        const imgElement = document.createElement("img");
        imgElement.src = event.target.result;

        imgElement.onload = (e) => {
          const canvas = document.createElement("canvas");
          const MAX_WIDTH = 500;

          const scaleSize = MAX_WIDTH / e.target.width;
          canvas.width = MAX_WIDTH;
          canvas.height = e.target.height * scaleSize;

          const ctx = canvas.getContext("2d");

          ctx.drawImage(e.target, 0, 0, canvas.width, canvas.height);

          // at 11:00 on YT https://www.youtube.com/watch?v=bXf_UdyDzSA
          this.image = canvas.toDataURL("image/jpeg", 0.9);
            
          let binary = atob(this.image.split(',')[1])
          let array = []
          for (var i = 0; i < binary.length; i++) {
            array.push(binary.charCodeAt(i))
          }
          let blobData = new Blob([new Uint8Array(array)], {type: 'image/jpeg'})
          this.resizedPhoto = blobData
          
          resolve('resize finished')
        }
      }
      })
    },
    async upload(){
        const createdItem = await API.graphql(
          graphqlOperation(createItem, {
            input: {
              name: this.uploadFile.name,
              playerName: this.user.username
            },
          }),
        )

      // upload to s3
      try {
        await Storage.put(createdItem.data.createItem.id, this.resizedPhoto, {
          level: 'public',
          contentType: this.uploadFile.type,
        })
        await this.listItems()
        this.apiRequest = false
      } catch (err) {
        console.error('Error uploading file: ', err)
      }

      // this.uploadFile = null
    },
    async removePhoto(photo){
      this.apiRequest = true
      try{
          await Storage.remove(photo.id, { level: 'public'})
          const inputPhoto = { id: photo.id };
              await API.graphql({
                  query: deleteItem,
                  variables: { input: inputPhoto },
              });
          
      } catch(err){
          console.log(err)
      }
      this.listItems()
      this.apiRequest = false
    },
    closePhotos(){
      this.dialogPhotos = false
    },
    async orderFilter(selectedFilter){
      // console.log(this.item.id + ' - ' + selectedFilter.filterName + ' - ' + selectedFilter.amount)
      try {
        this.apiRequest = true
        const inputPhoto = { id: this.item.id, filterSelected: selectedFilter.filterName, Amount: selectedFilter.amount  };
        await API.graphql({
            query: updateItem,
            variables: { input: inputPhoto },
        });
        this.dialogFilter = false
        this.selectedFilter = null
      } catch(err) {
          console.log(err.message)
      }
      this.listItems()
      this.apiRequest = false
    },
    async listItems() {
      this.apiRequest = true
      try {
        let filter = {playerName: {eq: this.user.username}};
        // const listedItems = await API.graphql(graphqlOperation(listItems))
        const listedItems1 = await API.graphql({ query: listItems, variables: { limit: 1000, filter: filter }})
        this.setNextToken = listedItems1.data.listItems.nextToken
        const setListedItems1 = await this.urlAdd(listedItems1.data.listItems.items)
        this.photos = setListedItems1

        // if there's too many data to be retrieved bcos it went above the limit of 1000, go to next 1000
        if(this.setNextToken!==null){
          const listedItems2 = await API.graphql({ query: listItems, variables: { limit: 1000, filter: filter, nextToken: this.setNextToken }})
          this.setNextToken = listedItems2.data.listItems.nextToken
          const setListedItems2 = await this.urlAdd(listedItems2.data.listItems.items)
          const comboSet = setListedItems1.concat(setListedItems2)
          this.photos = comboSet
        } else {
          console.log("Cust - Not using calendar 2 yet")
        }

        this.filterTotal = 0
        let arrayFilters2 = []
        let fetchedPhotos = this.photos
        fetchedPhotos.forEach(element => {
          arrayFilters2.push({
            id: element.id,
            filterSelected: element.filterSelected,
            name: element.name,
            amount: element.Amount,
            url: element.url,
            combo: element.filterSelected + ' - $' + element.Amount
          })
          this.filterTotal = this.filterTotal + element.Amount
        })
        this.items = arrayFilters2.sort((a,b) => a.name.localeCompare(b.name))

        this.apiRequest = false
      } catch (err) {
        console.log(err.message)
      }
      
      try {
        if(this.items.length < 1 && this.user){
          this.showNoPhotoPage = true
        }
        if(this.items.length > 30){
          this.reached30 = true
        }
      } catch(err) {
        console.log(err.message)
      }

    },
    async listItemsC(callback) {
      this.apiRequest = true
      try {
        let filter = {playerName: {eq: this.user.username}};
        // const listedItems = await API.graphql(graphqlOperation(listItems))
        const listedItems1 = await API.graphql({ query: listItems, variables: { limit: 1000, filter: filter }})
        this.setNextToken = listedItems1.data.listItems.nextToken
        const setListedItems1 = await this.urlAdd(listedItems1.data.listItems.items)
        this.photos = setListedItems1

        // if there's too many data to be retrieved bcos it went above the limit of 1000, go to next 1000
        if(this.setNextToken!==null){
          const listedItems2 = await API.graphql({ query: listItems, variables: { limit: 1000, filter: filter, nextToken: this.setNextToken }})
          this.setNextToken = listedItems2.data.listItems.nextToken
          const setListedItems2 = await this.urlAdd(listedItems2.data.listItems.items)
          const comboSet = setListedItems1.concat(setListedItems2)
          this.photos = comboSet
        } else {
          console.log("Cust - Not using calendar 2 yet")
        }
        this.filterTotal = 0
        let arrayFilters2 = []
        let fetchedPhotos = this.photos
        fetchedPhotos.forEach(element => {
          arrayFilters2.push({
            id: element.id,
            filterSelected: element.filterSelected,
            name: element.name,
            amount: element.Amount,
            url: element.url,
            combo: element.filterSelected + ' - $' + element.Amount
          })
          this.filterTotal = this.filterTotal + element.Amount
        })
        this.items = arrayFilters2.sort((a,b) => a.name.localeCompare(b.name))

        this.apiRequest = false
      } catch (err) {
        console.log(err.message)
      }
        callback(callback);
    },
    goToSlideShow(){
      this.showPayPal=false
      this.showSlideShow = true
      this.dialogOrient = false
    },
    playIf(){
      if(this.items.length < 1 ){ this.showNoPhotoPage = true }
      this.goToSlideShow()

    },
    async urlAdd(items) {
      const result = await Promise.all(
        items.map(async (item) => {
          item.url = await Storage.get(item.id, {
            level: 'public',
          })
          return item
        }),
      )

      return result
    },
    goToLogin(){
        this.dialogLogin = true
    },
    goToReport(){
      this.donationTotal=0
      this.getViewCount()
      this.getDonors()
      this.musicOff()
      this.dialogReport = true
    },
    goToPhotos(){
      this.musicOff()
      this.dialogPhotos = true
    },
    goToLink(){
      this.musicOff()
      this.donorsLink = window.location.href + 'player/' + this.user.username
      this.dialogLink = true
    },
    copyLink(){
      navigator.clipboard.writeText(this.donorsLink)
    },
    goLink(){
      window.open(this.donorsLink)
    },
    ackFilterInfo(){
      this.showFilterInfo = false
      this.dialogFilter = true
    },
    goToFilter(item){
      if(this.showFilterInfoFirst === true){
        this.showFilterInfo = true
        this.showFilterInfoFirst = false
      } else {
        this.dialogFilter = true
      }
      
      this.item = item
      this.selectedFilter = item
    },         
    goToMusicCredit(){
      this.dialogMusicCredit = true
    },
    goToBug(){
      this.dialogReportBug = true
    },
    goToCustomize(){
      this.dialogCustomize = true
    },
    goToIdea(){
      this.dialogGotIdea = true
    },
    goToIntro(){
      this.musicOff()
      this.$router.push('/intro')
    },
    goToSample(){
      this.musicOff()
      this.$router.push('/sample')
    },
    backToLogin(){
      this.dialogRegister = false
      this.dialogLogin = true
    },
    async isUserSignedIn(){
      try {
        const userObj = await Auth.currentAuthenticatedUser()
          this.signedIn = true
          this.user = userObj
      } catch (err) {
          this.signedIn = false
          this.dialogLogin = true
          this.apiRequest = false
      }
    },
    async signin(){
      try {
        this.showSlideShow = false
        this.apiRequest=true
        this.errorLogin = null
        this.messageLogin = null
        this.userReturn = await Auth.signIn({ username: this.playerName, password: this.playerPassword })
        this.dialogLogin = false
        this.dialogRegister = false
        this.dialogForgot = false
        this.apiRequest=false
      } catch(err) {
        this.errorLogin = err.message
      }
    },
    async register(){
      if (!this.playerName || !this.editorEmail || !this.playerPassword || !this.checkboxAgreement) {
        this.errorRegister = "Please fill in all the fields"
        this.alertMissingInfo = true
        return; }
      this.apiRequest=true
      try {
        await Auth.signUp({
            username: this.playerName,
            password: this.playerPassword,
            attributes: { email: this.editorEmail,
                          address: this.editorPassword,
                          nickname: this.teamName }
        });  
        this.dialogRegister = false
        this.dialogConfirm = true
        this.dialogLogin = false
        this.apiRequest=false
      } catch (err) {
        if(err.message==='User already exists'){
          this.errorRegister = 'Player Name already exists' }
          else { this.errorRegister = err.message }
        console.log("error")
      }
    },
    async createViewsEntry(){

        try{
          await API.graphql(
                    graphqlOperation(createViews, {
                      input: {
                        owner: this.playerName,
                        playerName: this.playerName,
                        viewCount: 0
                      },
                    }),
                  )
        } catch(err) {
          console.log(err.message)
        }

    },
    async confirmSignUp(){
      this.apiRequest=true
      try {
        await Auth.confirmSignUp(this.playerName, this.confirmCode)
        this.signin()
        this.isUserSignedIn()
        
        var self = this
        setTimeout(function () {
          self.createViewsEntry()
          console.log("here")
        }, 10000);
        this.dialogConfirm = false
      } catch (err) {
        this.errorConfirm = err.message
      }
      this.apiRequest=false
    },
    async resendCode(){
      this.apiRequest=true
      try {
          await Auth.resendSignUp(this.playerName);
          this.errorConfirm = ""
          this.confirmCode = ""
          this.errorResendCode = ""
          this.apiRequest=false
      } catch (err) {
          this.errorResendCode = err.message
      }
    },
    async forgot(){
      try {
        await Auth.forgotPassword(this.playerName)
        this.dialogForgot = false
        this.dialogLogin = false
        this.dialogForgotSubmit = true
      } catch(err){
        this.errorForgot = err.message
      }
    },
    async forgotSubmit(){
      this.apiRequest=true
      this.errorLogin = null
      this.messageLogin = null
      try {
        await Auth.forgotPasswordSubmit(this.playerName, this.newCode, this.newPassword)
        this.playerPassword = this.newPassword
        this.dialogForgotSubmit=false
        this.messageLogin = 'Succesful password reset... login again.'
        this.dialogLogin=true
        this.apiRequest=false
      } catch(err){
        this.errorForgotSubmit = err.message
      }
      this.apiRequest=false
    },
    async signOut() {
      try {
          await Auth.signOut();
          this.musicOff()
          this.drawer=false
          this.user=null
          this.signedIn=false
          this.dialogPhotos = false
          this.dialogLogin = true
          this.items = []
      } catch (err) {
          alert(err.message)
      }
    },
  },
  watch: {
    userReturn: function(){
      this.isUserSignedIn()
    },
    user: function(){
      if(this.user !== null){
        this.listItemsC(this.playIf)
      }
    }
  }
};
</script>

<style>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  margin-top: 6px;
  margin: 0;
  padding: 0;
}

#vol{
  text-align: center;
  margin-top: 20px;
}

h3{
  text-align: center;
}

</style>
