<template>
    <v-container fill-height>      
      <v-app-bar app clipped-left color=black>
        <v-avatar class="mr-2" @click.stop="drawer = !drawer" size="36px"><img src="/Logo.jpg" alt="Avatar"></v-avatar>

    <!-- Please Donate -->
      <v-spacer></v-spacer>
      <v-toolbar-title>
        <div class="row">
          <div v-if="showPlsSupport" :style="{'font-size':'15px'}" class="col-12 text-truncate animate__animated animate__fadeInBottomLeft yellow--text"> Please help support my team...
        </div>
        </div>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn  v-bind="size" @click="goToPayPal" v-if="showButtonDonate" :class="animateType" class="animate__animated blue darken-3 white--text font-weight-bold">please donate</v-btn>      </v-app-bar>
    
    <!-- Show Image   -->
      <v-row  class="justify-center align-center">
        <v-col>
          <carousel-3d v-if="showSlideShow" :autoplay="true" :autoplayTimeout="2000" :perspective="70" :controls-visible="true" :controls-prev-html="'&#10092;'" :controls-next-html="'&#10093;'" 
                :controls-width="30" :controls-height="60" :clickable="false" :count="items.length" :border="0" :height="240">
            <slide v-for="(item, i) in items" :index="i" :key="i">
              <figure>
                <img v-bind:src="item.url">
              </figure>
            </slide>
          </carousel-3d>
        </v-col>
      </v-row>

    <!-- Navigation Drawer -->
      <v-navigation-drawer v-model="drawer" absolute temporary>
        <v-list shaped>
          <h3 v-if="user">Player Name -  {{ playerName }}</h3>
            <v-list-item-group v-model="item" color="blue darken-3">
            <v-list-item v-if="!showSlideShow" @click="goToSlideShow"><v-list-item-icon><v-icon color=black>mdi-restart</v-icon></v-list-item-icon><v-list-item-title>Restart Show</v-list-item-title></v-list-item>
            <v-list-item v-if="!playMusic" @click="musicOn"><v-list-item-icon><v-icon color=black>mdi-music</v-icon></v-list-item-icon><v-list-item-title>Play Music</v-list-item-title></v-list-item>
            <v-list-item v-if="playMusic" @click="musicOff"><v-list-item-icon><v-icon color=black>mdi-music-off</v-icon></v-list-item-icon><v-list-item-title>Stop Music</v-list-item-title></v-list-item>
            <v-list-item @click="goToPayPal"><v-list-item-icon><v-icon color=black>mdi-gift</v-icon></v-list-item-icon><v-list-item-title>Donate</v-list-item-title></v-list-item>
            <v-list-item @click="goToMusicCredit"><v-list-item-icon><v-icon color=black>mdi-comment-quote</v-icon></v-list-item-icon><v-list-item-title>Music Credit</v-list-item-title></v-list-item>
            <v-list-item @click="goToBug"><v-list-item-icon><v-icon color=black>mdi-bug</v-icon></v-list-item-icon><v-list-item-title>Report a Bug</v-list-item-title></v-list-item>
            <v-list-item @click="goToCustomize"><v-list-item-icon><v-icon color=black>mdi-wrench-outline</v-icon></v-list-item-icon><v-list-item-title>Customize</v-list-item-title></v-list-item>
            <v-list-item @click="goToIdea"><v-list-item-icon><v-icon color=black>mdi-head-lightbulb-outline</v-icon></v-list-item-icon><v-list-item-title>Got an app idea?</v-list-item-title></v-list-item>
            </v-list-item-group>
        </v-list>
      </v-navigation-drawer>
   <!-- Music Credit -->
      <v-dialog v-model="dialogMusicCredit">
        <v-card :style="{'background-image':'url(/window.jpg)', 'background-size':'cover', 'margin':'0', 'padding':'0'}">
          <v-img
            height="200px"
            src="https://dndhiglights-myapp2-net.s3.amazonaws.com/piano-ga18e9ea77_1920.jpg"
          ></v-img>
            <v-row justify="center" no-gutters>
              <h2 class="my-15 mx-5 white--text">Background Music is credited to Fesliyan Studios </h2>
            </v-row>
          </v-card>
      </v-dialog>
    <!-- Report Bug -->
      <v-dialog v-model="dialogReportBug">
        <v-card :style="{'background-image':'url(/window.jpg)', 'background-size':'cover', 'margin':'0', 'padding':'0'}">
          <v-img
            height="200px"
            src="https://cdn.pixabay.com/photo/2020/07/12/07/47/bee-5396362_1280.jpg"></v-img>
            <v-row justify="center" no-gutters>
              <h2 class="my-15 mx-5 white--text">Please email buildmyapp2@yahoo.com if you encountered a bug... </h2>
            </v-row>
          </v-card>
      </v-dialog>
    <!-- Customize -->
      <v-dialog v-model="dialogCustomize">
        <v-card :style="{'background-image':'url(/window.jpg)', 'background-size':'cover', 'margin':'0', 'padding':'0'}">
          <v-img
            height="200px"
            src="https://dndhiglights-myapp2-net.s3.amazonaws.com/customize.jpg"></v-img>
            <v-row justify="center" no-gutters>
              <h2 class="my-15 mx-5 white--text">Please email buildmyapp2@yahoo.com if you would like for us to build a different type of photo slide app... </h2>
            </v-row>
          </v-card>
      </v-dialog>
    <!-- Got Idea -->
      <v-dialog v-model="dialogGotIdea">
        <v-card :style="{'background-image':'url(/window.jpg)', 'background-size':'cover', 'margin':'0', 'padding':'0'}">
          <v-img
            height="200px"
            src="https://dndhiglights-myapp2-net.s3.amazonaws.com/gotideas.jpg"></v-img>
            <v-row justify="center" no-gutters>
              <h2 class="my-15 mx-5 white--text">Please email buildmyapp2@yahoo.com if you have an app idea that you would like to partner with us...</h2>
            </v-row>
          </v-card>
      </v-dialog>
    <!-- Turn On Volume -->
      <v-dialog v-model="dialogVolume" persistent>
        <v-card :style="{'background-image':'url(/window.jpg)', 'background-size':'cover', 'margin':'0', 'padding':'0'}"> 
          <v-img class="mx-auto" src="/turnupvol3.gif" width="120"></v-img>
          <v-row justify="center" no-gutters>
            <h3 class="white--text">Please try to be in a quiet place and set volume to highest level to fully appreciate the beautiful music... </h3>
          </v-row>
          <v-row justify="center" class="ma-4" no-gutters>
            <v-btn class="mb-5" @click="goToOrient">done</v-btn>
          </v-row>
        </v-card>
      </v-dialog>
    <!-- Landscape Orientation-->
      <v-dialog v-model="dialogOrient" persistent>
        <v-card :style="{'background-image':'url(/window.jpg)', 'background-size':'cover', 'margin':'0', 'padding':'0'}"> 
          <v-img class="mx-auto" src="/phonerotate3.gif" max-width="120" max-height="120">
          </v-img>
          <v-row justify="center" no-gutters>
            <h3 class="white--text">Please turn your device sideways for better viewing experience</h3>
          </v-row>
          <v-row justify="center" class="ma-4">
            <v-btn class="mb-5" @click="goToSlideShow">done</v-btn>
          </v-row>
        </v-card>
      </v-dialog>
    <!-- FB -->
      <v-dialog v-model="dialogFB" persistent>
        <v-card :style="{'background-image':'url(/window.jpg)', 'background-size':'cover', 'margin':'0', 'padding':'0'}"> 
          <v-img class="mx-auto" src="/phonerotate3.gif" max-width="120" max-height="120">
          </v-img>
          <v-row justify="center" no-gutters>
            <h3 class="white--text">Please turn device sideways. If you're not able to, you can still click "DONE" but you'll miss out on the 3D effects. Facebook has a default setting that does not allow for it; however, you can change your FB setting OR copy the link and paste in your browser (ie safari or chrome) </h3>
          </v-row>
          <v-row justify="center" class="ma-4">
            <v-btn class="mb-5 mx-4" @click="goToSlideShow">done</v-btn>
            <v-btn class="mb-5 mx-4" @click="copyLink">copy</v-btn>
          </v-row>
        </v-card>
      </v-dialog>
    <!-- Desktop -->
      <v-dialog v-model="dialogDesktop" persistent>
        <v-card :style="{'background-image':'url(/window.jpg)', 'background-size':'cover', 'margin':'0', 'padding':'0'}"> 
          <v-img class="mx-auto" src="/phone.png" max-width="120" max-height="120">
          </v-img>
          <v-row justify="center" no-gutters>
            <h3 class="white--text mt-7">It appears you are viewing this webapp from a computer and not in a handheld device. For better viewing experience, this webapp is meant to be viewed from a handheld device just like the way donors would. </h3>
          </v-row>
          <v-row justify="center" class="ma-4">
            <v-btn class="mb-5 mx-4 mt-7" @click="goToSlideShow">done</v-btn>
          </v-row>
        </v-card>
      </v-dialog>
    <!-- Password Protected -->
      <v-dialog v-model="dialogPasswordProtected" persistent>
        <v-card :style="{'background-image':'url(/window.jpg)', 'background-size':'cover', 'margin':'0', 'padding':'0'}"> 
          <v-row justify="center" no-gutters>
            <h3 class="white--text">It appears this page is password protected. Please ask your sender for a password and a different link</h3>
          </v-row>
          <!-- <v-row justify="center" class="ma-4" no-gutters>
            <v-btn class="mb-5" @click="goToOrient">done</v-btn>
          </v-row> -->
        </v-card>
      </v-dialog>
  </v-container>
</template>

<script>
import { Carousel3d, Slide } from "vue-carousel-3d";
import { useSound } from '@vueuse/sound'
import buttonSfx from '../assets/audio.mp3'
import { Auth, API, Storage } from 'aws-amplify';
import { listItems, listViews } from '@/graphql/queries'
import { updateViews } from '@/graphql/mutations'

export default {
  name: "Player",
  components: {
    Carousel3d,
    Slide
  },
  data() {
    return {
      dialogDesktop: false,
      dialogFB: false,
      size: null,
      dialogPasswordProtected: false,
      dialogOrient: false,
      dialogVolume: false,
      showSlideShow: true,
      animateType: 'animate__fadeInBottomRight',
      showButtonDonate: false,
      showPlsSupport: false,
      loaded: false,
      paidFor: false,
      orderNum: null,
      showPayPal: false,
      donorName: "",
      donatedAmount: null,
      teamName: "",
      filterTotal: 0,
      showFilterInfo: false,
      showFilterInfoFirst: true,
      image: "",
      resizedPhoto: undefined,
      photos: [],
      filter: [],
      dialogFilter: false,
      dialogGotIdea: false,
      dialogCustomize: false,
      dialogReportBug: false,
      dialogMusicCredit: false,
      errorEditorPassword: "",
      dialogEditorPassword: false,
      chkEditorPassword: "",
      isEditor: false,
      showNoPhotoPage: false,
      switch1: false,
      setNextToken: null,
      playMusic: false,
      uploadFile: null,
      file: null,
      items: [],
      item: [],

      alertMissingInfo: "",
      confirmCode: null,
      dialogConfirm: false,
      dialogForgotSubmit: false,
      dialogLogin: false,
      dialogRegister: false,
      dialogForgot: false,
      dialogPhotos: false,

      errorLogin: "",
      errorRegister: "",
      errorConfirm: "",
      errorForgot: "",
      errorForgotSubmit: "",
      errorResendCode: "",

      messageLogin: null,
      newCode: "",
      newPassword: "",
      playerPassword: "",
      editorEmail: "",
      editorPassword: "",
      signedIn: false,
      username: "",
      user: undefined,
      email: "",
 
      rules: {
        required: value => !!value || 'Required.',
        blank: value => !(/[ ]/.test(value)) || 'no spaces allowed',
        counter: value => value.length <= 20 || 'Max 20 characters',
        email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || 'Invalid e-mail.'
        },
      },

      apiRequest: false,
      apiRequestCircular: false,
      drawer: false,
      startSlide: false,

    };
  },
  setup() {
    const { play, stop } = useSound(buttonSfx, {onend: () => { play() },})
    return {
      play,
      stop,
    }
  },
  computed:{
    playSound(){
      return this.play
    },
    playerName(){
      return this.$route.params.playerName;
    }
  },
  mounted(){
    this.signin()
  },
  methods:{
    startTextAnimation(){
      var self = this
      setTimeout(function () {
        self.showPlsSupport = true;
      }, 15000);
      setTimeout(function () {
        self.showButtonDonate = true;
      }, 19000);
      setTimeout(function () {
        self.showButtonDonate = false;
        self.animateType = 'animate__jello'
        self.showButtonDonate = true;
      }, 27000);
      setTimeout(function () {
        self.showButtonDonate = false;
        self.animateType = 'animate__heartBeat'
        self.showButtonDonate = true;
      }, 40000);
      setTimeout(function () {
        self.showButtonDonate = false;
        self.animateType = 'animate__tada'
        self.showButtonDonate = true;
      }, 50000);
      setTimeout(function () {
        self.showButtonDonate = false;
        self.animateType = 'animate__flash'
        self.showButtonDonate = true;
      }, 60000);
      setTimeout(function () {
        self.showButtonDonate = false;
        self.animateType = 'animate__swing'
        self.showButtonDonate = true;
      }, 70000);
      setTimeout(function () {
        self.showButtonDonate = false;
        self.animateType = 'animate__jello'
        self.showButtonDonate = true;
      }, 80000);
      setTimeout(function () {
        self.showButtonDonate = false;
        self.animateType = 'animate__heartBeat'
        self.showButtonDonate = true;
      }, 90000);
      setTimeout(function () {
        self.showButtonDonate = false;
        self.animateType = 'animate__tada'
        self.showButtonDonate = true;
      }, 100000);
      setTimeout(function () {
        self.showButtonDonate = false;
        self.animateType = 'animate__flash'
        self.showButtonDonate = true;
      }, 110000);
      setTimeout(function () {
        self.showButtonDonate = false;
        self.animateType = 'animate__swing'
        self.showButtonDonate = true;
      }, 120000);
    },
    goToPayPal(){
      this.musicOff()
      this.$router.push('/paypal')
    },
    musicOn(){
      this.playSound()
      this.playMusic = true
    },
    musicOff(){
      this.playMusic = false
      this.stop()
    },
    async incrementViewCount(){
      try {
        let filter = {playerName: {eq: this.playerName}};
        const listedViews = await API.graphql({ query: listViews, variables: { filter: filter } })
        const prevViewCount = listedViews.data.listViews.items
        let newCountId = prevViewCount[0].id
        let count = prevViewCount[0].viewCount

        // console.log(newCountId + ' - ' + count)

        let newCount = count + 1
        // console.log(newCount)

        const inputViewCount = { id: newCountId, viewCount: newCount };
        await API.graphql({
            query: updateViews,
            variables: { input: inputViewCount },
        });
      } catch(err){
        console.log(err.message)
      }
    },
    async urlAdd(items) {
      const result = await Promise.all(
        items.map(async (item) => {
          item.url = await Storage.get(item.id, {
            level: 'public',
          })
          return item
        }),
      )

      return result
    },
    async listItems() {
      this.apiRequest = true
      try {
        // const listedItems = await API.graphql(graphqlOperation(listItems))
        const listedItems1 = await API.graphql({ query: listItems, variables: { limit: 1000 }})
        this.setNextToken = listedItems1.data.listItems.nextToken
        const setListedItems1 = await this.urlAdd(listedItems1.data.listItems.items)
        this.photos = setListedItems1

        // if there's too many data to be retrieved bcos it went above the limit of 1000, go to next 1000
        if(this.setNextToken!==null){
          const listedItems2 = await API.graphql({ query: listItems, variables: { limit: 1000, nextToken: this.setNextToken }})
          this.setNextToken = listedItems2.data.listItems.nextToken
          const setListedItems2 = await this.urlAdd(listedItems2.data.listItems.items)
          const comboSet = setListedItems1.concat(setListedItems2)
          this.photos = comboSet
        } else {
          console.log("Cust - Not using calendar 2 yet")
        }

        this.filterTotal = 0
        let arrayFilters2 = []
        let fetchedPhotos = this.photos
        fetchedPhotos.forEach(element => {
          arrayFilters2.push({
            id: element.id,
            filterSelected: element.filterSelected,
            name: element.name,
            amount: element.Amount,
            url: element.url,
            combo: element.filterSelected + ' - $' + element.Amount
          })
          this.filterTotal = this.filterTotal + element.Amount
        })
        this.items = arrayFilters2.sort((a,b) => a.name.localeCompare(b.name))

        this.apiRequest = false
      } catch (err) {
        console.log(err.message)
      }
      
      try {
        if(this.items.length < 1 && this.user){
          this.showNoPhotoPage = true
        }
      } catch(err) {
        console.log(err.message)
      }

    },
    async listItemsC(callback) {
      this.apiRequest = true
      try {
        // const listedItems = await API.graphql(graphqlOperation(listItems))
        let filter = {playerName: {eq: this.playerName}};
        // const listedItems1 = await API.graphql({ query: listItems, variables: { limit: 1000, filter: filter, authMode: 'AWS_IAM' }})
        // const listedItems1 = await API.graphql({ query: listItems, variables: { limit: 1000, filter: filter }})
        const listedItems1 = await API.graphql({ query: listItems, variables: { limit: 1000, filter: filter }})
        this.setNextToken = listedItems1.data.listItems.nextToken
        const setListedItems1 = await this.urlAdd(listedItems1.data.listItems.items)
        this.photos = setListedItems1

        // if there's too many data to be retrieved bcos it went above the limit of 1000, go to next 1000
        if(this.setNextToken!==null){
          const listedItems2 = await API.graphql({ query: listItems, variables: { limit: 1000, filter: filter,  nextToken: this.setNextToken }})
          this.setNextToken = listedItems2.data.listItems.nextToken
          const setListedItems2 = await this.urlAdd(listedItems2.data.listItems.items)
          const comboSet = setListedItems1.concat(setListedItems2)
          this.photos = comboSet
        } else {
          console.log("Cust - Not using calendar 2 yet")
        }
        this.filterTotal = 0
        let arrayFilters2 = []
        let fetchedPhotos = this.photos
        fetchedPhotos.forEach(element => {
          arrayFilters2.push({
            id: element.id,
            filterSelected: element.filterSelected,
            name: element.name,
            amount: element.Amount,
            url: element.url,
            combo: element.filterSelected + ' - $' + element.Amount
          })
          this.filterTotal = this.filterTotal + element.Amount
        })
        this.items = arrayFilters2.sort((a,b) => a.name.localeCompare(b.name))

        this.apiRequest = false
      } catch (err) {
        console.log(err.message)
        console.log("end")
      }
        callback(callback);
    },
    goToTurnOnVol(){
      this.dialogVolume = true
    },
    goToOrient(){
      this.dialogVolume = false
        if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
          var ua = navigator.userAgent || navigator.vendor || window.opera
          if (ua.indexOf("FBAN") > -1 || (ua.indexOf("FBAV") > -1)){
            this.dialogFB = true
          } else {
            this.dialogOrient = true
          }        
        } else {
          this.dialogDesktop = true
        }

    },
    copyLink(){
      navigator.clipboard.writeText(window.location.href)
    },
    goToSlideShow(){
      this.showPayPal=false
      this.dialogDesktop = false
      this.showSlideShow = true
      this.dialogOrient = false
      this.dialogFB = false
      this.musicOn() 
      if(!this.paidFor){
        this.startTextAnimation()
      }
    },
    playIf(){
      this.isUserSignedIn()
      if (this.items.length > 0){ 
        this.goToTurnOnVol()
      }  else if(this.items.length < 1 ){ this.showNoPhotoPage = true }
    },
    goToMusicCredit(){
      this.dialogMusicCredit = true
    },
    goToBug(){
      this.dialogReportBug = true
    },
    goToCustomize(){
      this.dialogCustomize = true
    },
    goToIdea(){
      this.dialogGotIdea = true
    },
    async isUserSignedIn(){
      try {
        const userObj = await Auth.currentAuthenticatedUser()
          this.signedIn = true
          this.user = userObj
      } catch (err) {
          this.signedIn = false
          this.dialogLogin = true
          this.apiRequest = false
      }
    },
    async signin(){
      try {
        this.incrementViewCount()

        this.showSlideShow = false
        this.apiRequest=true
        this.errorLogin = null
        this.messageLogin = null
        await Auth.signIn({username: 'myathlete', password: 'athlete1' })
        this.listItemsC(this.playIf)        
        this.dialogLogin = false
        this.dialogRegister = false
        this.dialogForgot = false
        this.apiRequest=false
      } catch(err) {
        this.errorLogin = err.message
        this.dialogPasswordProtected = true
      }
    },
  }
};
</script>

<style>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  margin-top: 6px;
  margin: 0;
  padding: 0;
}

#vol{
  text-align: center;
  margin-top: 20px;
}

h3{
  text-align: center;
}

</style>
