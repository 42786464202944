<template>
    <div>
        <v-row>
            <v-btn class="ma-5" color="black" dark @click="goBack">Back</v-btn>
        </v-row>
        <h1 class="ma-5 white--text">Maximize Donations</h1>
        <p class="text-justify white--text ma-5">
            According to Stanford School of Business (6/27/07), "... researchers found that if organizations want to raise money for a charitable cause, it is far better to appeal to the heart than to the head..."
            Our webapp is built to warm up donors using a mesmerizing 3D slide show of beatiful photos and captivating music before asking for donations.
        </p>
        <h1 class="ma-5 mt-10 white--text">Tug Heartstrings First</h1>
        <p class="text-justify white--text ma-5">
            Most youth fund-raising go directly to "tugging on wallets" which does not help maximize donations. 
            A webapp is a perfect way to take advantage of the latest technology and provide visual/audio stimuli that will tug on heartstrings first.
            It waits until the music reach a certain threshold before asking for donor support. This strategy has been proven effective by one softball team that was able to raise about $300 on average per player.
        </p>
        <h1 class="ma-5 mt-10 white--text">Easy and Effective</h1>
        <p class="text-justify white--text ma-5">
            There is no need to bake and/or sell goods or provide a service. It does not involve major planning or stocking up or coordinating with others. Raising money using this webapp is as simple as uploading memorable photos from your phone and sending text messages to your families and friends.
            Please go to "Sample Texts" in the dropdown to find effective ways of sharing the link to your donors.
        </p>
    </div>

</template>
<script>
export default {
    methods:{
        goBack(){
            this.$router.push('/')
        }
    }
}
</script>