<template>
  <v-app>
    <v-main :style="{'background-image':'url(/mainbg.jpg)', 'background-size':'cover', 'margin':'0', 'padding':'0'}">
      <transition
        name="fade"
        mode="out-in"
      >
        <router-view/>
      </transition>
    </v-main>
  </v-app>
</template>

<script>
// import Slides from './components/Slides';

export default {
  name: 'App',

  components: {
    // Slides,
  },

  data: () => ({
    //
  }),
};
</script>
<style>
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0
}
</style>